import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';

const dataProviderName = 'companies/legalentity';
const model = 'companies/legalEntity';
const drawerRouteBase = 'legalentities';


const Create = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const [cap, setCap] = useState();

    // DATA PROVIDER
    const { item, save, loadOne, loadAll, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);
    const { item: config } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config])

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        //setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        setDetailConfig({
            "tabs": [
                {
                    "name": "create_new_legal_entity",
                    "fieldsets": [
                        {
                            "name": "legal_entity_information",
                            "fields": {
                                "name": {
                                    "type": "text",
                                    "required": true,
                                    "className": "col-6",
                                    "placeholder": "insert",
                                    "icon": "faInputText",
                                    "label": "legal_entity_name"
                                },
                                "legalForm": {
                                    "type": "select",
                                    "required": true,
                                    "options": [
                                        {
                                            "label": "srl",
                                            "value": "srl"
                                        },
                                        {
                                            "label": "spa",
                                            "value": "spa"
                                        },
                                        {
                                            "label": "sas",
                                            "value": "sas"
                                        },
                                        {
                                            "label": "snc",
                                            "value": "snc"
                                        }
                                    ],
                                    "className": "col-2",
                                    "placeholder": "insert"
                                },
                                "vat": {
                                    "type": "text",
                                    "required": true,
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "status": {
                                    "type": "select",
                                    "required": true,
                                    "options": [
                                        {
                                            "label": "active",
                                            "value": "active"
                                        },
                                        {
                                            "label": "inactive",
                                            "value": "inactive"
                                        }
                                    ],
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "type": {
                                    "type": "select",
                                    "required": true,
                                    "options": [
                                        {
                                            "label": "customer",
                                            "value": "customer"
                                        },
                                        {
                                            "label": "supplier",
                                            "value": "supplier"
                                        },
                                        {
                                            "label": "partner",
                                            "value": "partner"
                                        },
                                        {
                                            "label": "holding",
                                            "value": "holding"
                                        },
                                        {
                                            "label": "public_authority",
                                            "value": "public_authority"
                                        }
                                    ],
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "industry": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "agricultureAndLivestock",
                                            "value": "agricultureAndLivestock"
                                        },
                                        {
                                            "label": "foodAndBeverage",
                                            "value": "foodAndBeverage"
                                        },
                                        {
                                            "label": "manufacturing",
                                            "value": "manufacturing"
                                        },
                                        {
                                            "label": "construction",
                                            "value": "construction"
                                        },
                                        {
                                            "label": "energyAndUtilities",
                                            "value": "energyAndUtilities"
                                        },
                                        {
                                            "label": "wholesaleAndRetailTrade",
                                            "value": "wholesaleAndRetailTrade"
                                        },
                                        {
                                            "label": "transportationAndLogistics",
                                            "value": "transportationAndLogistics"
                                        },
                                        {
                                            "label": "informationTechnology",
                                            "value": "informationTechnology"
                                        },
                                        {
                                            "label": "financialServices",
                                            "value": "financialServices"
                                        },
                                        {
                                            "label": "healthcareAndPharmaceuticals",
                                            "value": "healthcareAndPharmaceuticals"
                                        },
                                        {
                                            "label": "educationAndTraining",
                                            "value": "educationAndTraining"
                                        },
                                        {
                                            "label": "realEstate",
                                            "value": "realEstate"
                                        },
                                        {
                                            "label": "tourismAndHospitality",
                                            "value": "tourismAndHospitality"
                                        },
                                        {
                                            "label": "entertainmentAndMedia",
                                            "value": "entertainmentAndMedia"
                                        },
                                        {
                                            "label": "professionalServices",
                                            "value": "professionalServices"
                                        },
                                        {
                                            "label": "publicAdministration",
                                            "value": "publicAdministration"
                                        }
                                    ],
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "supplier_type": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "renter",
                                            "value": "renter"
                                        },
                                        {
                                            "label": "highway",
                                            "value": "highway"
                                        },
                                        {
                                            "label": "petrol",
                                            "value": "petrol"
                                        },
                                        {
                                            "label": "tyre",
                                            "value": "tyre"
                                        },
                                        {
                                            "label": "workshop",
                                            "value": "workshop"
                                        }
                                    ],
                                    "className": "col-12",
                                    "placeholder": "insert",
                                    "visibilitycondition": [
                                        [
                                            "type",
                                            "=",
                                            "supplier"
                                        ]
                                    ]
                                },
                                "holding": {
                                    "type": "relationshipSingle",
                                    "relation": {
                                        "drawerRoute": "legalentities"
                                    },
                                    "view": "legalEntity",
                                    "capability": "company.holding.manage",
                                    "visibilitycondition":[
                                        [
                                            "type",
                                            "=",
                                            "customer"
                                        ]
                                    ]
                                },
                            }
                        },
                        {
                            "name": "contacts",
                            "fields": {
                                "telephone": {
                                    "type": "text",
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "email": {
                                    "type": "email",
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "website": {
                                    "type": "text",
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "preferences": {
                                    "type": "sectiontitle",
                                    "className": "col-12"
                                },
                                "currency": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "EUR",
                                            "value": "EUR"
                                        },
                                        {
                                            "label": "GBP",
                                            "value": "GBP"
                                        },
                                        {
                                            "label": "CHF",
                                            "value": "CHF"
                                        },
                                        {
                                            "label": "USD",
                                            "value": "USD"
                                        }
                                    ],
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "measurementSystem": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "metric",
                                            "value": "metric"
                                        },
                                        {
                                            "label": "imperial",
                                            "value": "imperial"
                                        }
                                    ],
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                                "dateFormat": {
                                    "type": "select",
                                    "options": [
                                        {
                                            "label": "dd/mm/yyyy",
                                            "value": "dd/mm/yyyy"
                                        },
                                        {
                                            "label": "mm/dd/yyyy",
                                            "value": "mm/dd/yyyy"
                                        }
                                    ],
                                    "className": "col-4",
                                    "placeholder": "insert"
                                },
                            }
                        }
                    ]
                }
            ],
            "validation": []
        });

        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    // load from dataProvider
    let loaded = false;
    useEffect(() => {
        if (!loaded && (!item || item.id != params.filters?.id)) {
            loadOne(params.filters?.id);
        }
        loaded = true;
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])


    const { save: saveJob } = useDataProvider('queues/job');
    const handleSave = async (data) => {
        saveJob && await saveJob({ action: 'create', model: model ? model : dataProviderName, data: data });
        close();
        loadAll();
    }

    return <>
        <DetailComponent
            values={{ ...item?.data }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New Legal Entity')}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create new Legal Entity')}
                    subtitle={t('Fill the basic information to create a new Legal Entity that you will be able to edit via the standard interface')}
                />
            }
            {...params}
        />
    </>
}

export default Create;


