import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDetailConfigOverrider, useDrawer, useRelationshipInjector } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';

const dataProviderName = 'cars/cost';
const model = 'cars/cost';
const drawerRouteBase = 'costs';


const Create = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const [cap, setCap] = useState();
    const { injectToDetail } = useRelationshipInjector();
    const { overrideDetailFields } = useDetailConfigOverrider();

    // DATA PROVIDER
    const { item, save, loadOne, loadAll, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);
    const { item: config } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config])

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        if (params.detailConfigOverride) {
            setDetailConfig(overrideDetailFields(config.data.modules[dataProviderName].detailConfig, params.detailConfigOverride));
        } else {
            setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        }

        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    // load from dataProvider
    let loaded = false;
    useEffect(() => {
        if (!loaded && (!item || item.id != params.filters?.id)) {
            loadOne(params.filters?.id);
        }
        loaded = true;
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])

    injectToDetail(item?.data, 'car', params.car);
    injectToDetail(item?.data, 'legalEntity', params.legalEntity);
    injectToDetail(item?.data, 'employment', params.employment);
    injectToDetail(item?.data, 'valueCurrency', 'EUR');
    injectToDetail(item?.data, 'costType', 'work_cost');
    injectToDetail(item?.data, 'invoiced_at', new Date())
    injectToDetail(item?.data, 'status', 'tobepaid')
    

    console.info('detailConfig create Cost', detailConfig)

    const { save: saveJob } = useDataProvider('queues/job');
    const handleSave = async (data) => {
        saveJob && await saveJob({ action: 'create', model: model ? model : dataProviderName, data: data });
        close();
        loadAll();
    }

    return <>
        <DetailComponent
            values={{ ...item?.data }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New Cost')}
            header={
                <InfoHeader
                    view="wizard"
                    title={t('Create new Cost')}
                    subtitle={t('Fill the basic information to create a new Cost that you will be able to edit via the standard interface')}
                />
            }
            {...params}
        />
    </>
}

export default Create;


