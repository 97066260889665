
import React from 'react';
import { useState, useEffect } from 'react';
import { InfoHeader, Detail as DetailComponent, Confirm, useDrawer, SplitButton } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { useTranslation } from 'react-i18next';

const dataProviderName = 'cars/car';
const drawerRouteBase = 'cars';

const Detail = (params) => {
    const { t } = useTranslation();
    const { activate, close: closeDrawer } = useDrawer();
    const [cap, setCap] = useState();

    // DATA PROVIDER
    const { item, save, loadOne, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);
    const { item: config } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config])

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    // load from dataProvider
    let loaded = false;
    useEffect(() => {
        if (!loaded && (!item || item.id != params.id)) {
            loadOne(params.id);
        }
        loaded = true;
    }, []);

    // Ricerco i servizi collegati per poi passare il filtro al bottone di aggiunta costi
    const [assignedServices, setAssignedServices] = useState([])
    useEffect(() => {

        let services = [];
        const direct = item.data.services?.map((service) => service._id).flat(Infinity);
        const contract = item.data.contract?.services?.map((service) => service._id).flat(Infinity);
        const employment = item.data.pools?.map((pool) => pool.employments?.map((employment) => employment.services?.map((service) => service._id))).flat(Infinity);

        if (typeof direct != 'undefined') services.push(...direct);
        if (typeof contract != 'undefined') services.push(...contract);
        if (typeof employment != 'undefined') services.push(...employment);

        setAssignedServices(services);
    }, item);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    //await params.handleClose(params.uid, item.data);
                    await params.handleClose(params.uid)
                } catch (e) { }
            })()
        }
    }, [success])

    const handleSave = (data) => {
        save && save({ ...data, filters: params.filters });
    }

    const [confirmDelete, setConfirmDelete] = useState()
    const handleDelete = (id) => {
        setConfirmDelete(
            <Confirm
                oklabel={t("Delete")}
                okcallable={() => {
                    destroy && destroy(id);
                    closeDrawer();
                }}
                kolabel={t("Cancel")}
                kocallable={() => { setConfirmDelete(null) }}
                title={t("Item elimination")}>
                {t("Are you sure to remove this element?")}
            </Confirm>
        );
    }

    return (
        <>
            <DetailComponent
                values={{ ...item?.data }}
                modelName={modelName} detailConfig={detailConfig}
                onEditing={setEditing}
                save={handleSave}
                saving={saving}
                errors={errors}
                errorMessage={errorMessage}
                title={(item?.data.plate) ? item?.data.plate : t('No plate yet assigned')}
                header={
                    <InfoHeader
                        view="vehicle"
                        item={item?.data}
                    />
                }
                rightButtons={[
                    <SplitButton
                        actions={[
                            {
                                restoreMe_disabled: cap && !cap[dataProviderName + '.statusmanagement'],
                                icon: "faChartNetwork",
                                label: t('Manage Status'),
                                onClick: () => { activate(drawerRouteBase + '/statusManagement', { detailStack: params.detailStack ?? {}, car: item?.data, legalEntity: item?.data?.legalEntity }); }
                            },
                            {
                                restoreMe_disabled: cap && !cap[dataProviderName + '.platemanagement'],
                                icon: "faInputNumeric",
                                label: t('Manage Plate'),
                                onClick: () => { activate(drawerRouteBase + '/plateManagement', { detailStack: params.detailStack ?? {}, car: item?.data, legalEntity: item?.data?.legalEntity }); }
                            },
                            {
                                restoreMe_disabled: cap && !cap['costs.create'],
                                icon: "faMoneyBill",
                                label: t('Add Cost'),
                                onClick: () => {
                                    activate('costs/create', {
                                        detailStack: params.detailStack ?? {},
                                        car: item?.data,
                                        legalEntity: item?.data?.legalEntity,
                                        detailConfigOverride: {
                                            "tabs": [
                                                {
                                                    "fieldsets": [
                                                        {
                                                            "fields": {
                                                                "service": {
                                                                    "type": "servicelist",
                                                                    "className": "col-12",
                                                                    "hideTableToolbar": true,
                                                                    "filters": {
                                                                        "items": [
                                                                            {
                                                                                "field": "_id",
                                                                                "operator": "isAnyOf",
                                                                                "value": assignedServices
                                                                            }
                                                                        ]
                                                                    },
                                                                },
                                                            }
                                                        }
                                                    ]
                                                }
                                            ]

                                        }
                                    },
                                    () => { loadOne(params.id); });
                                }
                            },
                            {
                                restoreMe_disabled: cap && !cap['tracekms.create'],
                                icon: "faRoad",
                                label: t('Add Trace Km'),
                                onClick: () => { activate('tracekms/create', { detailStack: params.detailStack ?? {}, car: item?.data, legalEntity: item?.data?.legalEntity }); }
                            },
                            {
                                restoreMe_disabled: cap && !cap[dataProviderName + '.contractassociation'],
                                icon: "faFileSignature",
                                label: t('Assign a Contract'),
                                onClick: () => { activate(drawerRouteBase + '/contractAssociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                restoreMe_disabled: cap && !cap[dataProviderName + '.contractdissociation'],
                                icon: "faFileSignature",
                                label: t('Remove a Contract'),
                                onClick: () => { activate(drawerRouteBase + '/contractDissociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                restoreMe_disabled: cap && !cap[dataProviderName + '.serviceassociation'],
                                icon: "faCreditCard",
                                label: t('Assign a Service'),
                                onClick: () => { activate(drawerRouteBase + '/serviceAssociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                restoreMe_disabled: cap && !cap[dataProviderName + '.servicedissociation'],
                                icon: "faCreditCard",
                                label: t('Remove a Service'),
                                onClick: () => { activate(drawerRouteBase + '/serviceDissociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                restoreMe_disabled: cap && !cap[dataProviderName + '.personassociation'],
                                icon: "faPerson",
                                label: t('Assign to a Driver'),
                                onClick: () => { activate(drawerRouteBase + '/personAssociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                restoreMe_disabled: cap && !cap[dataProviderName + '.persondissociation'],
                                icon: "faPerson",
                                label: t('Remove a Driver'),
                                onClick: () => { activate(drawerRouteBase + '/personDissociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                restoreMe_disabled: cap && !cap[dataProviderName + '.poolassociation'],
                                icon: "faPeople",
                                label: t('Assign to a Pool'),
                                onClick: () => { activate(drawerRouteBase + '/poolAssociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                restoreMe_disabled: cap && !cap[dataProviderName + '.pooldissociation'],
                                icon: "faPeople",
                                label: t('Remove from Pool'),
                                onClick: () => { activate(drawerRouteBase + '/poolDissociation', { detailStack: params.detailStack ?? {} }); }
                            },
                            {
                                restoreMe_disabled: cap && !cap[dataProviderName + '.archive'],
                                icon: "faTrash",
                                label: t('Delete'),
                                onClick: () => { handleDelete(item.data.id); }
                            },
                        ]}
                    />
                ]}
                {...params}
            />

            {confirmDelete}
        </>
    )
}

export default Detail;



