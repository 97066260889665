import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, InfoHeader, useDrawer } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';

const dataProviderName = 'persons/person';
const model = 'persons/person';
const drawerRouteBase = 'persons';


const Create = (params) => {

    const { t } = useTranslation();
    const { close } = useDrawer();
    const [cap, setCap] = useState();

    // DATA PROVIDER
    const { item, save, loadOne, loadAll, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);
    const { item: config } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config])

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        //setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        setDetailConfig({
            "tabs": [
                {
                    "name": "create_new_person",
                    "fieldsets": [
                        {
                            "name": "create_new_person",
                            "fields": {
                                "personal_information": {
                                    "type": "sectiontitle",
                                    "className":"col-12"
                                },
                                "firstname": {
                                    "type": "text",
                                    "required": "true",
                                    "className":"col-6"
                                },
                                "lastname": {
                                    "type": "text",
                                    "required": "true",
                                    "className":"col-6"
                                },
                                "email": {
                                    "type": "text",
                                    "required": "true",
                                    "className":"col-6"
                                },
                                "fiscalcode": {
                                    "type": "text",
                                    "required": "true",
                                    "className":"col-6"
                                },
                                "legal_entity_information": {
                                    "type": "sectiontitle",
                                    "className":"col-12"
                                },
                                "legalEntity": {
                                    "type": "relationshipSingle",
                                    "relation": {
                                        "drawerRoute": "legalentities",
                                        "inverseRelation": "person",
                                        "inverseRelationType": "relationship",
                                        "filter": {
                                            "items": [{
                                                "field":"type",
                                                "operator":"equals",
                                                "value":"customer",
                                            }]
                                        },
                                        "limit": 1000
                                    },
                                    "view": "legalEntity",
                                    "required": "true"
                                },
                                "employments.role": {
                                    "type": "text",
                                    "required": "true",
                                    "className":"col-12"
                                }
                            }
                        }
                    ]
                }
            ],
            "validation": []
        });

        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    // load from dataProvider
    let loaded = false;
    useEffect(() => {
        if (!loaded && (!item || item.id != params.filters?.id)) {
            loadOne(params.filters?.id);
        }
        loaded = true;
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])


    const { save: saveJob } = useDataProvider('queues/job');
    const handleSave = async (data) => {
        saveJob && await saveJob({ action: 'create', model: model ? model : dataProviderName, data: data });
        close();
        loadAll();
    }

    return <>
        <DetailComponent
            values={{ ...item?.data }}
            modelName={modelName}
            detailConfig={detailConfig}
            onEditing={setEditing}
            save={handleSave}
            saving={saving}
            errors={errors}
            errorMessage={errorMessage}
            title={t('New Person')}
            header={
                <InfoHeader 
                    view="wizard" 
                    title={t('Create new Person')} 
                    subtitle={t('Fill the basic information to create a new Person that you will be able to edit via the standard interface')} 
                />
            }
            {...params}
        />
    </>
}

export default Create;


