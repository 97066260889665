import React from 'react';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Detail as DetailComponent, SectionTitleEmbedded, Tag } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';

const dataProviderName = 'persons/person';
const drawerRouteBase = 'persons';

const EmploymentsOfPerson = (params) => {

    const { t } = useTranslation();

    const [cap, setCap] = useState();

    // DATA PROVIDER
    const { item, save, loadOne, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, params.multipart);
    const { item: config } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config])

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        //setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        setDetailConfig({
            "tabs": [
                {
                    "name": "company_data",
                    "fieldsets": [
                        {
                            "name": "company_data",
                            "fields": {
                                "employments": {
                                    "type": "relationship",
                                    "relation": {
                                        "drawerRoute": "employments",
                                        "inverseRelation": "person",
                                        "inverseRelationType": "relationship",
                                        "limit": 1000
                                    },
                                    "view": "employments"
                                }
                            }
                        }
                    ]
                }
            ],
            "validation": []
        });

        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    // load from dataProvider
    let loaded = false;
    useEffect(() => {
        if (!loaded && (!item || item.id != params.id)) {
            loadOne(params.id);
        }
        loaded = true;
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    await params.handleClose(params.uid, item.data)
                } catch (e) { }
            })()
        }
    }, [success])

    const handleSave = (data) => {
        save && save({ ...data, filters: params.filters });
    }

    return <>
        {
            item &&
            <>
                <SectionTitleEmbedded 
                    label={ item?.data?.updated_at ? 
                        t('Assign a role to') + ' ' + item?.data?.firstname + ' ' + item?.data?.lastname +' @ ' +item?.data?.legalEntity?.name
                        :
                        t('Assign a role to the new person') 
                    } 
                    className={'col-12'}
                />
                <DetailComponent
                    values={{ ...item?.data }}
                    modelName={modelName}
                    detailConfig={detailConfig}
                    onEditing={setEditing}
                    save={handleSave}
                    saving={saving}
                    errors={errors}
                    errorMessage={errorMessage}
                    isEmbedded={true}
                    {...params}
                />
            </>
        }
    </>
}

export default EmploymentsOfPerson;


