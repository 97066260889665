import React from 'react';
import { useState, useEffect } from 'react';
import { Detail as DetailComponent } from '../../../../mtska-frontend-app-component';
import { useDataProvider } from '../../../../mtska-frontend-data-provider';
import { useRelationshipInjector } from '../../../../mtska-frontend-app-component';

import { useTranslation } from 'react-i18next';

const dataProviderName = 'documents/document';
const drawerRouteBase = 'documents';


const Detail = (params) => {
    const { t } = useTranslation();
    const [cap, setCap] = useState();
    const { injectToDetail } = useRelationshipInjector();

    // DATA PROVIDER
    const { item, save, loadOne, destroy, errorMessage, errors, saving, success } = useDataProvider(dataProviderName, true);
    const { item: config } = useDataProvider('configs/config');

    useEffect(() => {
        if (typeof config?.data?.capabilities === 'undefined') return;
        setCap(config.data.capabilities)
    }, [config])

    const [detailConfig, setDetailConfig] = useState();
    const [modelName, setModelName] = useState();
    useEffect(() => {
        if (typeof config?.data?.modules === 'undefined') return;
        if (typeof config.data.modules[dataProviderName] === 'undefined') return;
        setDetailConfig(config.data.modules[dataProviderName].detailConfig);
        setModelName(config.data.modules[dataProviderName].modelName);
    }, [config, dataProviderName]);

    // load from dataProvider
    let loaded = false;
    useEffect(() => {
        if (!loaded && (!item || item.id != params.filters?.id)) {
            if (typeof params.filters?._id !== 'undefined') {
                loadOne(params.filters._id);
            } else {
                loadOne();
            }
        }
        loaded = true;
    }, []);

    const [editing, setEditing] = useState(false)

    useEffect(() => {
        if (success && editing) {
            (async () => {
                try {
                    //await params.handleClose(params.uid, item.data);
                    await params.handleClose(params.uid)
                } catch (e) { }
            })()
        }
    }, [success])

    const handleSave = (data) => {
        save && save({ ...data, filters: params.filters });
    }
    
    injectToDetail(item?.data, 'ownerModel', params.filters?.modelName);
    injectToDetail(item?.data, 'ownerId', params.filters?._id);
    injectToDetail(item?.data, 'legalEntity', params.filters?.legalEntity);

    return (<>
            <DetailComponent
                {...params}

                values={{ ...item?.data }}
                modelName={modelName}
                detailConfig={detailConfig}
                onEditing={setEditing}
                save={handleSave}
                saving={saving}
                errors={errors}
                errorMessage={errorMessage}
                rightButtons={[]}
                title={(item?.data?.filename) ? item.data.filename : t('New document')}
            />
            </>
    )
}

export default Detail;


